<template>
  <div class="root">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">我的评价</span>
        <p class="nav-box">
          <span
            @click="handleNav(nav)"
            :class="activeNav.title === nav.title ? 'active' : ''"
            v-for="(nav, i) in navArr"
            :key="i"
            >{{ nav.title }}</span
          >
        </p>
      </div>
      <div v-if="evaluationList.length > 0">
        <div v-for="(item, i) in evaluationList" :key="i" class="item">
          <p class="time">{{ item.evaluateTime }}</p>
          <el-row>
            <el-col :span="activeNav.num === 0 ? 15 : 11">
              <div class="info">
                <el-image
                  style="width: 200px; height: 100px"
                  :src="item.coursePicture"
                  fit="cover"
                ></el-image>
                <div class="text">
                  <p>{{ item.courseName }}</p>
                </div>
              </div>
            </el-col>
            <el-col :span="5">教师：{{ item.teacherName }}</el-col>
            <el-col :span="4"
              ><el-popover placement="left" width="260" trigger="hover">
                <div v-if="activeNav.num == 0">
                  <div
                    v-for="(val, j) in item.evaluateStudentChildren"
                    :key="j"
                  >
                    {{ val.itemName }}：{{ val.comprehensiveEvaluation }}
                  </div>
                </div>
                <div v-else-if="activeNav.num == 1">
                  <div
                    v-for="(val, j) in item.evaluateTeacherChildren"
                    :key="j"
                    style="display: flex"
                  >
                    <span>{{ val.itemName }}：</span>
                    <el-rate
                      style="display: inline-block"
                      disabled
                      :value="val.comprehensiveEvaluation-0"
                    ></el-rate>

                  </div>
                </div>
                <div v-else>
                  <div
                    v-for="(val, j) in item.evaluateCourseChildren"
                    :key="j"
                    style="text-align: right ;"
                  >
                    <span>{{ val.itemName }}：</span>
                    <el-rate
                      style="display: inline-block"
                      disabled
                      :value="val.comprehensiveEvaluation-0"
                    ></el-rate>
                  </div>
                </div>
                <span class="cancel" slot="reference">评价详情</span>
              </el-popover></el-col
            >
            <el-col v-if="activeNav.num !== 0" :span="4">
              <span
                v-if="item.evaluateTeacherChildren.length > 0"
                @click="updateEvaluation(item.evaluateTeacherChildren)"
                class="cancel"
                >重新评价</span
              >
              <span
                v-else
                @click="updateEvaluation(item.evaluateCourseChildren)"
                class="cancel"
                >重新评价</span
              >
            </el-col>
          </el-row>
        </div>
        <el-dialog title="重新评价" :visible.sync="dialogFormVisible">
          <el-form :model="updateEvaluationData">
            <el-form-item
              v-for="(item, i) in updateEvaluationData.list"
              :key="i"
              :label="item.itemName"
              label-width="150px"
            >
              <!-- <el-radio-group v-model="item.comprehensiveEvaluation">
                <el-radio label="A"></el-radio>
                <el-radio label="B"></el-radio>
                <el-radio label="C"></el-radio>
              </el-radio-group> -->
              <el-rate
                style="display: inline-block"
                v-model="item.comprehensiveEvaluation"
              ></el-rate>
            </el-form-item>
            <el-form-item
                v-for="(item, i) in updateEvaluationData.list"
                :key="i"
                :label="item.itemName"
                label-width="150px"
            >
              <!-- <el-radio-group v-model="item.comprehensiveEvaluation">
                <el-radio label="A"></el-radio>
                <el-radio label="B"></el-radio>
                <el-radio label="C"></el-radio>
              </el-radio-group> -->
              <el-rate
                  style="display: inline-block"
                  v-model="item.comprehensiveEvaluation"
              ></el-rate>
            </el-form-item>
            <el-form-item
                v-for="(item, i) in updateEvaluationData.list"
                :key="i"
                :label="item.itemName"
                label-width="150px"
            >
              <!-- <el-radio-group v-model="item.comprehensiveEvaluation">
                <el-radio label="A"></el-radio>
                <el-radio label="B"></el-radio>
                <el-radio label="C"></el-radio>
              </el-radio-group> -->
              <el-rate
                  style="display: inline-block"
                  v-model="item.comprehensiveEvaluation"
              ></el-rate>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirmEvaluation"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
      <Empty v-else>暂无评价记录</Empty>
      <div class="pages">
        <el-pagination
          v-if="total > size"
          @current-change="toggleCurrent"
          :current-page="current"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="size"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  getEvaluationStudentList_api,
  getEvaluationTeacherList_api,
  getEvaluationCourseList_api,
  updateEvaluationCourse_api,
  updateEvaluationTeacher_api,
} from "@/api/user.js";
import Empty from "@/components/empty.vue";
export default {
  components: { Empty },
  data() {
    return {
      iconClasses: ["icon-rate-face-1", "icon-rate-face-2", "icon-rate-face-3"],
      size: 5, // 每页数量
      current: 1, // 当前页
      evaluationList: [], // 评价数据
      updateEvaluationData: {}, // 重新评价的数据
      dialogFormVisible: false, // 重新评价模态框的显示隐藏
      total: 0, // 总数
      activeNav: { title: "我的评价", num: 0 }, // 当前激活的导航，默认评价课程
      navArr: [
        // 所有导航
        { title: "我的评价", num: 0 },
        { title: "评价老师", num: 1 },
        { title: "评价课程", num: 2 },
      ],
    };
  },
  methods: {
    // 获取教师对学生的评价
    async getEvaluationStudentList() {
      let {
        total,
        resultEvaluationStudentList,
      } = await getEvaluationStudentList_api({
        currentPage: this.current,
        pageSize: this.size,
        userId: this.$store.state.userInfo.id,
      });
      if (resultEvaluationStudentList.length === 0 && this.current > 1) {
        this.current--;
        this.getEvaluationStudentList();
      } else {
        this.total = total;
        this.evaluationList = resultEvaluationStudentList;
      }
    },
    // 获取学生对教师的评价
    async getEvaluationTeacherList() {
      let {
        total,
        resultEvaluationTeacherList,
      } = await getEvaluationTeacherList_api({
        currentPage: this.current,
        pageSize: this.size,
        userId: this.$store.state.userInfo.id,
      });
      if (resultEvaluationTeacherList.length === 0 && this.current > 1) {
        this.current--;
        this.getEvaluationTeacherList();
      } else {
        this.total = total;
        this.evaluationList = resultEvaluationTeacherList;
      }
    },
    // 获取学生对课程的评价
    async getEvaluationCourseList() {
      let {
        total,
        resultEvaluationCourseList,
      } = await getEvaluationCourseList_api({
        currentPage: this.current,
        pageSize: this.size,
        userId: this.$store.state.userInfo.id,
      });
      if (resultEvaluationCourseList.length === 0 && this.current > 1) {
        this.current--;
        this.getEvaluationCourseList();
      } else {
        this.total = total;
        this.evaluationList = resultEvaluationCourseList;
      }
    },
    // 切换状态导航
    handleNav(val, current = 1) {
      this.current = current;
      this.activeNav = { ...val };
      if (val.num === 0) {
        // 获取教师对学生的评价
        this.getEvaluationStudentList();
      } else if (val.num === 1) {
        // 获取学生对教师的评价
        this.getEvaluationTeacherList();
      } else {
        // 获取学生对课程的评价
        this.getEvaluationCourseList();
      }
    },
    // 切换页码
    toggleCurrent(current) {
      this.current = current;
      if (this.activeNav.num === 0) {
        // 获取教师对学生的评价
        this.getEvaluationStudentList();
      } else if (this.activeNav.num === 1) {
        // 获取学生对教师的评价
        this.getEvaluationTeacherList();
      } else {
        // 获取学生对课程的评价
        this.getEvaluationCourseList();
      }
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    // 重新评价
    updateEvaluation(data) {
      data.forEach(item=>{
        item.comprehensiveEvaluation = item.comprehensiveEvaluation-0
      })
      let obj = { list: data };
      this.updateEvaluationData = JSON.parse(JSON.stringify(obj));
      this.dialogFormVisible = true;
    },
    // 确认修改评价
    async confirmEvaluation() {
      let update = new Function();
      if (this.activeNav.num === 1) {
        update = updateEvaluationTeacher_api;
      } else if (this.activeNav.num === 2) {
        update = updateEvaluationCourse_api;
      }

      let { code, msg } = await update({
        list: JSON.stringify(this.updateEvaluationData.list),
      });
      if (code === 200) {
        this.dialogFormVisible = false;
        this.$message({
          type: "success",
          message: msg,
        });
        this.handleNav(this.activeNav, this.current);
      } else {
        this.$message.error(msg);
      }
    },
  },
  created() {
    this.getEvaluationStudentList();
  },
};
</script>

<style lang="less" scoped>
@title-color: #0079fe;
@btn-color: #f66866;
.box-card {
  margin-bottom: 30px;
  .clearfix {
    display: flex;
    .title {
      margin-right: 20px;
      font-weight: 700;
    }
    .nav-box {
      span {
        padding: 4px 10px;
        font-size: 14px;
        user-select: none;
        cursor: pointer;
        &.active {
          color: @title-color;
          border-bottom: 1px solid @title-color;
        }
      }
    }
  }
  .item {
    margin-bottom: 20px;
    .time {
      line-height: 40px;
      background-color: #f2f2f2;
      text-indent: 1em;
      font-size: 14px;
    }
    /deep/.el-col {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      color: #999;
      font-size: 14px;
      line-height: 1.5em;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      &:first-child {
        justify-content: left;
      }
      .cancel {
        user-select: none;
        cursor: pointer;
      }
      .info {
        display: flex;
        .text {
          margin-left: 10px;
        }
      }
    }
  }
  /deep/.el-dialog {
    width: 400px;
    .el-dialog__body {
      padding: 10px 20px 0;
      .el-form-item{
        margin-bottom:0px;
      }
    }
  }
  .pages {
    display: flex;
    justify-content: center;
  }
}
</style>
