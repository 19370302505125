<template>
  <div>
    <el-card class="root-card">
      <div slot="header" class="clearfix">
        <span class="title">评价管理</span>
        <p class="nav-box">
          <span
            @click="handleNav(nav)"
            :class="activeNav.title === nav.title ? 'active' : ''"
            v-for="(nav, i) in navArr"
            :key="i"
            >{{ nav.title }}</span
          >
        </p>
      </div>
      <div v-if="evaluationStudentList.length > 0" class="main">
        <div
          class="main-item"
          v-for="(item, index) in evaluationStudentList"
          :key="index"
        >
          <div class="main-thead">
            <h4>课程时间：{{ item.courseLearningTime }}</h4>
            <div>
              <p style="width: 60%" class="th">{{ item.courseName }}</p>
              <p style="width: 25%" class="th">
                授课人：{{ item.teacherName }}
              </p>
              <p
                v-if="activeNav.num == 0"
                @click="handleInfo(item, index)"
                style="width: 15%"
                class="th toInfo"
              >
                {{ item.studentList.length > 0 ? "评价详情" : "暂不能评价" }}
              </p>
              <p v-else style="width: 15%" @click="handleInfo(item, index)" class="th toInfo">
                {{ item.studentList.length > 0 ? "评价详情" : "暂无评价" }}
              </p>
            </div>
          </div>
          <div :class="item.showTable?'main-tbody show':'main-tbody'">
            <el-table
              size="mini"
              stripe
              :data="item.studentList"
              style="width: 100%"
            >
              <el-table-column
                prop="studentName"
                label="姓名"
                width="80"
                align="center"
              >
              </el-table-column>
              <el-table-column label="性别" width="50">
                <template slot-scope="scope">
                  <span>{{ scope.row.sex == 1 ? "男" : "女" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="gradeName"
                label="年级"
                width="80"
              >
              </el-table-column>
              <el-table-column
                prop="schoolName"
                label="学校"
                width="200"
              >
              </el-table-column>
              <el-table-column label="评价详情" align="center">

                <template slot-scope="scope">
                  <!-- 教师评价学生 -->
                  <div
                      style="display: flex;align-items: center;margin-left: 200px"
                      v-for="(val, j) in scope.row.evaluateStudentItemEntity"
                      :key="j"
                  >

                    <div v-if="!item.isShow && val.comprehensiveEvaluation">
                      <span style="margin-right: 10px">{{ val.itemName }}:</span>
                      <span style="margin-right: 10px">{{ val.comprehensiveEvaluation }}</span>
                      <span style="margin-left: 10px" v-if="val.comprehensiveEvaluation == 'B' || val.comprehensiveEvaluation == 'C'">原因:</span>
                      <span style="margin-left: 10px" v-if="val.comprehensiveEvaluation == 'B' || val.comprehensiveEvaluation == 'C'">{{ val.reason }}</span>
                    </div>

                    <div style="display: flex;align-items: center" v-else>
                      <span style="margin-right: 10px">{{ val.itemName }}:</span>
                      <el-radio-group width="300" v-model="val.comprehensiveEvaluation">
                        <el-radio style="margin-right: 10px" label="A"
                        >A</el-radio
                        >
                        <el-radio style="margin-right: 10px" label="B"
                        >B</el-radio
                        >
                        <el-radio style="margin-right: 10px" label="C"
                        >C</el-radio
                        >
                      </el-radio-group>

                      <span style="margin-left: 10px" v-if="val.comprehensiveEvaluation == 'B' || val.comprehensiveEvaluation == 'C'">原因:</span>
                      <div style="margin-left: 10px;">
                        <el-input
                            size="small"
                            width="200"
                            placeholder="请输入原因"
                            v-if="val.comprehensiveEvaluation == 'B' || val.comprehensiveEvaluation == 'C'"
                            v-model="val.reason"
                        ></el-input>
                      </div>
                    </div>

                  </div>
                  <!-- 学生评价教师 -->
                  <div
                      v-for="(val, j) in scope.row.evaluateTeacherItemList"
                      :key="j"
                  >
                    <span style="margin-right: 10px">{{ val.itemName }}:</span
                    ><span>{{ val.comprehensiveEvaluation }}</span>
                  </div>
                </template>

              </el-table-column>
            </el-table>
<!--            && (item.studentList[0].evaluateStudentItemEntity[0]?item.studentList[0].evaluateStudentItemEntity[0].comprehensiveEvaluation == false :true)-->
            <p v-if="activeNav.num == 0 && item.isShow" style="text-align: center">
              <el-button @click="updateEvaluationStudentA(item.studentList,index)" size="small" style="margin: 10px auto;margin-right: 20px;" type="primary" round
              >全选A</el-button>
              <el-button @click="updateEvaluationStudent(item.studentList,index)" size="small" style="margin: 10px auto" type="primary" round
                >提交评价</el-button>
            </p>
          </div>
        </div>
        <div style="width: 50%;margin: 0 auto;" v-if="isComfire == true && isk==true ">
          <div
              width="700"
              style="position:fixed;z-index: 12;top: 50%;left:50%;transform: translate(-50%,-50%);background: #fff;box-shadow: 0.2rem 0.2rem 1rem #eee;border-radius: 0.5rem;padding: 1.2rem 1.2rem 0 1.2rem;"
          >
            <p style="color: #666;padding-bottom: 2rem;">确认评价</p>
            <el-table :data="gridData" size="mini" stripe>
              <el-table-column width="150" property="studentName" align="center" label="姓名"></el-table-column>
              <el-table-column width="150" property="comprehensiveEvaluation" align="center" label="评价等级"></el-table-column>
              <el-table-column width="400" property="reason" align="center" label="原因"></el-table-column>
            </el-table>
            <p style="text-align:right;padding: 0.5rem;box-sizing: border-box;">
              <el-button @click="cancel()" size="small" style="margin: 10px auto;cursor: pointer" type="info" round
              >取消</el-button>
              <el-button @click="comfire()" size="small" style="margin: 10px auto;margin-left: 2rem;cursor: pointer;" type="primary" round
              >确认</el-button>
            </p>
          </div>
        </div>
      </div>
      <Empty v-else>暂无评价记录</Empty>
      <div class="pages">
        <el-pagination
          v-if="total > size"
          @current-change="toggleCurrent"
          :current-page="current"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="size"
        >
        </el-pagination>
      </div>
    </el-card>
    <div v-if="isComfire == true && isk==true " style="position: fixed;top: 0;bottom: 0;left: 0;right: 0;text-align: center;z-index: 10;background: rgba(0,0,0,0.1)"></div>

  </div>
</template>

<script>
import {
  teacherGetEvaluationStudentList_api,
  teacherGetEvaluationTeacherList_api,
  updateEvaluationStudent_api,
} from "@/api/user.js";
import Empty from "@/components/empty.vue";
export default {
  components: {
    Empty,
  },
  data() {
    return {
      activeNav: { title: "评价学生", num: 0 },
      navArr: [
        { title: "评价学生", num: 0 },
        // { title: "我的评价", num: 1 },
      ],
      current: 1,
      size: 3,
      total: 0,
      isShow:false,
      isk:true,
      isComfire:false,
      evaluationStudentList: [], // 评价学生的数据
      gridData:[],
      comfireList:[],//确认提交评价数据
    };
  },
  methods: {
    // 获取评价学生的数据
    async teacherGetEvaluationStudentList() {
      let {
        total,
        resultEvaluationStudentList,
      } = await teacherGetEvaluationStudentList_api({
        currentPage: this.current,
        pageSize: this.size,
        userId: this.$store.state.userInfo.id,
      });
      this.total = total;
      this.evaluationStudentList = resultEvaluationStudentList.map(item => {
        if(item.studentList.length > 0){
          item.studentList.forEach(x => {
            if(x.evaluateStudentItemEntity.length > 0){
              if(x.evaluateStudentItemEntity[0].comprehensiveEvaluation == ''){
                item.isShow = true
                return item
              }
            }
          })
        }
        item.showTable = false // 给每一个课程添加是否展开评价详情的标杆
        return item
      });

    },
    // 获取学生对教师的评价列表
    async teacherGetEvaluationTeacherList() {
      let {
        total,
        resultEvaluationTeacherList,
      } = await teacherGetEvaluationTeacherList_api({
        currentPage: this.current,
        pageSize: this.size,
        userId: this.$store.state.userInfo.id,
      });
      this.total = total;
      this.evaluationStudentList = resultEvaluationTeacherList.map(item => {
        item.showTable = false // 给每一个课程添加是否展开评价详情的标杆
        return item
      });
    },
    // 切换状态导航
    handleNav(val, current = 1) {
      if(this.activeNav.num==val.num) return null
      this.current = current;
      this.activeNav = { ...val };
      if (this.activeNav.num === 0) {
        // 获取评价学生的数据
        this.teacherGetEvaluationStudentList();
      } else {
        // 获取学生对教师的评价
        this.teacherGetEvaluationTeacherList();
      }
    },
    // 切换页码
    toggleCurrent(current) {
      this.current = current;
      if (this.activeNav.num === 0) {
        // 获取评价学生的数据
        this.teacherGetEvaluationStudentList();
      } else {
        // 获取学生对教师的评价
        this.teacherGetEvaluationTeacherList();
      }
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    // 查看详情
    handleInfo(item, index) {
      if (item.studentList.length <= 0) {
        this.$message.error("该课程暂不能评价或查看评价");
      } else if(item.showTable) {
        this.evaluationStudentList.forEach(val=>{
          val.showTable = false // 关闭所有
        })
      }else{
        this.evaluationStudentList.forEach(val=>{
          val.showTable = false // 关闭所有
        })
        item.showTable = true
      }
    },
    // 全选A
    updateEvaluationStudentA(item,index){
      this.evaluationStudentList[index].studentList.forEach(x => {
          x.evaluateStudentItemEntity[0].comprehensiveEvaluation = 'A'
       })

    },

    cancel(){
      this.isComfire = false
    },
    async comfire(){
      let list = []
      this.comfireList.forEach(student=>{
        if(student.evaluateStudentItemEntity) list = list.concat(student.evaluateStudentItemEntity)
        else if(student.evaluateTeacherItemList) list = list.concat(student.evaluateTeacherItemList)
      })
      let {code,msg} = await updateEvaluationStudent_api({
        list:JSON.stringify(list)
      })
      if(code==200){
        this.$message({
          type:"success",
          message:msg
        })
        this.isComfire = false
        this.teacherGetEvaluationStudentList();
        this.handleNav(this.activeNav,this.current)
      }else{
        this.$message.error(msg)
      }
    },

    // 更新教师对学生的评价
    async updateEvaluationStudent(item,index){
      this.comfireList = []
      this.comfireList = item
      // 检测是否全部评价
      for(let i = 0; i<item.length ; i++){
        if(item[i].evaluateStudentItemEntity.length > 0){
          if(item[i].evaluateStudentItemEntity[0].comprehensiveEvaluation == ''){
            this.isK = false
            this.$message({
              type:"warning",
              message:'有学生还未评价，请全部评价完成再提交'
            })
            return
          }else{
            this.isK = true
          }
        }
      }
      if(this.isK){
        this.gridData = [] // 存放提交的数据
        item.forEach((student,index)=>{
          if(student.evaluateStudentItemEntity){
            this.gridData = this.gridData.concat(student.evaluateStudentItemEntity)
            this.gridData[index].studentName = student.studentName
          }
          else if(student.evaluateTeacherItemList){
            this.gridData = this.gridData.concat(student.evaluateTeacherItemList)
            this.gridData[index].studentName = student.studentName
          }
        })
        this.gridData = this.gridData.filter(x=>x.comprehensiveEvaluation !='A')
        if(this.gridData.length > 0){
          this.isComfire = true
        }else{
          this.comfire()
        }
      }
    },
  },
  created() {
    this.teacherGetEvaluationStudentList();
  },
};
</script>

<style lang="less" scoped>
@title-color: #0079fe;
.root-card {
  margin-bottom: 30px;
  .clearfix {
    display: flex;
    .title {
      margin-right: 20px;
      font-weight: 700;
    }
    .nav-box {
      span {
        padding: 4px 10px;
        font-size: 14px;
        user-select: none;
        cursor: pointer;
        &.active {
          color: @title-color;
          border-bottom: 1px solid @title-color;
        }
      }
    }
  }
  .main {
    .main-item {
      margin: 20px 10px;
      font-size: 14px;
      .main-thead {
        color: #777;
        h4 {
          padding-left: 20px;
          line-height: 30px;
          background-color: #eee;
        }
        & > div {
          border-left: 1px solid #eee;
          border-bottom: 1px solid #eee;
          min-height: 50px;
          line-height: 25px;
          display: flex;
          .th {
            border-right: 1px solid #eee;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            &.toInfo {
              cursor: pointer;
              user-select: none;
            }
          }
        }
      }
      .main-tbody {
        display: none;
        border-left:1px solid #eee;
        border-right:1px solid #eee;
        border-bottom:1px solid #eee;
        &.show{
          display: block;
        }
      }
    }
  }
  .pages {
    display: flex;
    justify-content: center;
  }
}
</style>
