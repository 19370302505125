<template>
  <div>
    <StudentEvaluate v-if='$store.state.userInfo.roleName === "学生" || $store.state.userInfo.roleName === "student"'/>
    <TeacherEvaluate v-else />
  </div>
</template>

<script>
import StudentEvaluate from "./studentEvaluate"
import TeacherEvaluate from "./teacherEvaluate"
  export default {
    components:{
      StudentEvaluate,
      TeacherEvaluate,
    },
    data(){
      return {

      }
    },
    methods:{

    },
  }
</script>

<style lang="less" scoped>

</style>